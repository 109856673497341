@import 'utils/variables.scss';
.menu-mobile {
  position:fixed;
  top:0;
  left:0;
  width:80%;
  max-width:200px;
  height:100vh;
  background-color:#FFF;
  flex-direction: column;
  justify-content: center;
  display:none;
  transform: translate3d(-100%,0,0);
  transition:transform 0.5s;
  z-index:2;
  a {
    display:block;
    font-family:'InterFace-Bold';
    font-size: 16px;
    line-height:24px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    padding:10px 30px;
    color:#000;
    transition:background-color 0.5s;
    &:hover, &.active {
      background-color: var(--background-color,#c8d2d5);
    }
  }
  @include md {
    display:flex;
  }
  &.open {
    transform: translate3d(0,0,0);
  }
}
.topbar {
  position:fixed;
  z-index:3;
  top:0;
  left:0;
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
  .burger{
    background:url('../../assets/Burger.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width:21px;
    height:21px;
    margin-left:15px;
    cursor:pointer;
    display:none;
    @include md {
      display:block;
    }
  }
  .menu {
    flex-grow: 1;
    display:flex;
    justify-content: center;
    a {
      font-family:'InterFace-Bold';
      font-size: 16px;
      line-height:24px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      padding:10px;
      color:#000;
      transition:background-color 0.5s;
      &:hover, &.active {
        background-color: var(--background-color,#c8d2d5);
      }
    }
    @include md {
      display:none;
    }
  }
  .title {
    padding:10px 15px;
    font-family:'InterFace-Bold';
    line-height:24px;
    font-size: 24px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    span {
      color:#000;
    }
    @include md {
      flex-grow: 1;
    }
  }
  .lang-selector {
    padding:10px 15px;
    font-family:'InterFace-Bold';
    font-size: 12px;
    line-height:24px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
}
