@import 'utils/variables.scss';
.biographie {
  padding-top:50px;
  padding-bottom:50px;
  display:flex;
  justify-content: center;
  .biographie-inner {
    width:600px;
    max-width:100%;
    padding:0 30px;
    box-sizing: border-box;
    .biographie-titre-principal {
      margin-top:50px;
      @include md {
        margin-top:30px;
      }
      font-family:'InterFace-Bold';
      font-size:24px;
      letter-spacing: 0.15em;
      text-transform:uppercase;
      text-align:center;
    }
    .biographie-logo {
      opacity:0.5;
      margin:20px 0;
      background-image: url(../../assets/logo_noir.svg);
      height:53px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .biographie-titre {
      margin-top:50px;
      @include md {
        margin-top:30px;
      }
      font-family:'InterFace-Bold';
      font-size:24px;
      letter-spacing: 0.15em;
      text-transform:uppercase;
      text-align:left;
    }
    .biographie-texte {
      font-size:20px;
      letter-spacing: 0.1em;
      text-align:left;
      @include md {
        font-size:16px;
      }
    }
  }
}
