@font-face {
  font-family: 'InterFace';
  src: url('./assets/fonts/InterFace.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/InterFace.woff') format('woff'),
  url('./assets/fonts/InterFace.ttf')  format('truetype'),
  url('./assets/fonts/InterFace.svg#InterFace') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'InterFace-Bold';
  src: url('./assets/fonts/InterFace-Bold.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/InterFace-Bold.woff') format('woff'),
  url('./assets/fonts/InterFace-Bold.ttf')  format('truetype'),
  url('./assets/fonts/InterFace-Bold.svg#InterFace-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}
body {
  font-family: 'InterFace';
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.clearfix {
    clear:both;
    width:100%;
}
