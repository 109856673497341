@import 'utils/variables.scss';
.modal {
    position:absolute;
    top:-100%;
    left:0;
    width:100%;
    height:100%;
    opacity:0;
    z-index:0;
    background-color:#0008;
    transition: opacity 0.5s, left 0.1s linear 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.modal.show {
    top:0;
    opacity:1;
    z-index:1000;
    transition: opacity 0.5s, top 0s;
}
.modal .modal-close {
    position:absolute;
    right: 15px;
    top: 15px;
    height:45px;
    width:45px;
    background-image: url(../assets/close.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
}
.modal .modal-container {
    width:39%;
    background-color: #FFF;
    border-radius:8px;
    min-height:300px;
    align-self:center;
    position:relative;
}
.modal h3.title {
    font-family: 'InterFace';
    font-size: 25px;
    letter-spacing: 0.1em;
    margin:100px 100px 30px 100px ;
    text-transform: uppercase;
}
.xs .modal h3.title {
    font-size: 22px;
}
.modal .content {
    font-size: 20px;
    margin:0 100px 100px 100px;
}
@media (max-width:767px) {
    .modal .modal-close {
        position:absolute;
        right: 15px;
        top: 15px;
        height:45px;
        width:45px;
        background-image: url(../assets/close.svg);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
    }
    .modal .modal-container {
        position: relative;
        align-self:center;
        width:90%;
        background-color: #FFF;
        border-radius:8px;
        min-height:300px;
    }
    .modal h3.title {
        margin:50px 50px 30px 50px ;
    }
    .modal .content {
        font-size: 20px;
        margin:0 50px 50px 50px;
    }

}
