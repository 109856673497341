@import 'utils/variables.scss';
.residences {
  padding-top:50px;
  padding-bottom:50px;
  display:flex;
  justify-content: center;
  .residences-left, .residences-top {
    width:250px;
    margin-top:30px;
    margin-right:150px;
    .residences-rub-logo {
      opacity:0.5;
      margin-top:50px;
      background-image: url(../../assets/logo_noir.svg);
      height:180px;
      @include md {
        margin-top:0;
        height:53px;
      }
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .residences-rub-texte {
      font-size:20px;
      @include md {
        font-size:16px;
      }
      letter-spacing: 0.1em;
      text-align:right;
    }
    .residences-rub-titre {
      margin-top:80px;
      @include md {
        margin-top:30px;
      }
      font-family:'InterFace-Bold';
      font-size:24px;
      letter-spacing: 0.15em;
      text-transform:uppercase;
      text-align:right;
    }
  }
  .residences-left {
    display:block;
    width:250px;
    @include xl {
      margin-left:50px;
      margin-right:50px;
    }
    @include md {
      display:none;
    }
  }
  .residences-top {
    width:100%;
    display:none;
    margin-bottom:50px;
    @include md {
      display:block;
      margin:0;
    }
    .residences-rub-texte, .residences-rub-titre  {
      text-align: left;
    }
  }

  .residences-inner {
    margin-top:80px;
    @include md {
      margin-top:30px;
    }
    width:900px;
    max-width:100%;
    padding:0 30px;
    box-sizing: border-box;
    .residences-image {
      width:100%;
      padding-bottom:35%;
      position:relative;
      img {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }
    .residences-titre {
      margin:10px 0;
      font-family:'InterFace-Bold';
      font-size:24px;
      letter-spacing: 0.15em;
      text-transform:uppercase;
      text-align:left;
    }
    .residences-texte {
      p:first-child {
        margin-top:0;
      }
      font-size:20px;
      @include md {
        font-size:16px;
      }
      letter-spacing: 0.1em;
      text-align:left;
      margin-bottom:50px;
    }
  }
}
