@import 'utils/variables.scss';
a, a:visited, a:active, a:focus, span.link {
    text-decoration:none;
    color:#999;
    cursor:pointer;
}
body {
	background: var(--background-color,#c8d2d5);
  transition: background-color 0.5s;
  overflow-x:hidden;
  -webkit-tap-highlight-color: transparent;
}
.App {
  @include viewPort
}
