@import 'utils/variables.scss';
.oeuvre-modal {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    opacity:0;
    z-index:3;
    transition: opacity 0.5s, left 0.1s linear 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}
.oeuvre-modal.show {
    opacity:1;
    z-index:1000;
    transition: opacity 0.5s, top 0s;
    pointer-events: all;
}
.oeuvre-modal .modal-inner {
    width:calc(100% - 20px);
    max-width:400px;
    background-color: #FFF;
    border-radius:8px;
    position:relative;
    text-transform: none;
    font-size:16px;
    box-sizing: border-box;
    padding:15px 30px;
    line-height:1.2;
    letter-spacing: 0;
    box-shadow: 0 0 100px #0008;
    .texte {
      max-height:calc(100vh - 150px);
      overflow-y: scroll;
      text-align:left;
    }
    .close {
      position:absolute;
      top:-12px;
      width:24px;
      right:5px;
      height:24px;
      background-image: url('../../assets/close2.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      cursor:pointer;
    }
}
