@import 'utils/variables.scss';
.oeuvres {
  width:100%;
  height:100vh;
  @include viewPort;
  overflow:hidden;
  display:flex;
  justify-content: center;
  align-items: center;
  .zoom-helper {
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    z-index:2;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#FFF;
    font-size:24px;
    pointer-events: none;
    .zoom-helper-inner {
      height:100%;
      width: 400px;
      max-width: 90%;
      padding:50px 100px;
      box-sizing: border-box;
      text-align:center;
      display:flex;
      justify-content: center;
      align-items: center;
      background-image: url('../../assets/helper.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    pointer-events: none;
    opacity:0;
    transition: opacity 0.5s;
    &.show {
      pointer-events: all;
      opacity:1;
    }
  }
  .pagination {
    position:relative;
    top:10px;
    width:0;
    height:0;
    .prev {
      opacity:0.5;
      position:absolute;
      top:0;
      right:30px;
      white-space: nowrap;
      display:flex;
      justify-content: center;
      align-items: center;
      cursor:pointer;
      span {
        display: inline-block;
        background-image: url('../../assets/chevron.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width:30px;
        height:16px;
      }
      &.active {
        opacity:1;
      }
    }
    .center {
      opacity:0.5;
      position:absolute;
      top:0;
      width:50px;
      right:-25px;
      height:16px;
      line-height:16px;
      text-align: center;
      white-space: nowrap;
      cursor:pointer;
    }
    .next {
      opacity:0.5;
      transition:opacity 0.5s;
      position:absolute;
      top:0;
      left:30px;
      white-space: nowrap;
      cursor:pointer;
      display:flex;
      justify-content: center;
      align-items: center;
      cursor:pointer;
      span {
        display: inline-block;
        background-image: url('../../assets/chevron.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width:30px;
        height:16px;
        transform: rotate(180deg);
      }
      &.active {
        opacity:1;
      }
    }
  }
  .background {
    position:fixed;
    z-index:0;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity:0;
    transition:opacity 0.2s;
  }
  .oeuvres-inner {
    width:100%;
    z-index:1;
    text-align:center;
    display:flex;
    flex-direction: column;
    align-items: center;
    .deltas {
      height:0;
      transition:height 0.5s;
    }
    .oeuvre {
      position:relative;
      text-transform: uppercase;
      width:100%;
      font-size:24px;
      line-height: 46px;
      @include md {
        line-height: 30px;
      }
      letter-spacing: 0.1em;
      width:100%;
      max-width:1920px;
      .i {
        position:absolute;
        top:0px;
        width:24px;
        right:20px;
        height:24px;
        background-image: url('../../assets/i.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.5s;
      }
      .detail {
        position:relative;
        width:100%;
        opacity:0;
        pointer-events: none;
        padding-bottom:0;
        transition:opacity 0.2s, padding-bottom 0.5s;
        position:relative;
        .detail-inner {
          position:absolute;
          width:100%;
          height:100%;
          left: 0;
          top: 0;
          .tableau {
            pointer-events: all;
            position:absolute;
            top:2%;
            left:25%;
            width:50%;
            @include lg {
              left:5%;
              width:55%;
            }
            @include sm {
              left:5%;
              width:90%;
            }
            height:100%;
            .tableau-wrapper {
              position:relative;
              width:100%;
              padding-bottom:$rpCadre;
              .tableau-container {
                position:absolute;
                width:100%;
                height:100%;
                left: 0;
                top: 0;
                .close {
                  position:absolute;
                  top: 0;
                  right:-60px;
                  width:24px;
                  height:24px;
                  background-image: url('../../assets/close.svg');
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: contain;
                  cursor:pointer;
                }
                .ombre {
                  z-index:1;
                  position:absolute;
                  width:100%;
                  height:100%;
                  left: 0;
                  top: 0;
                  box-shadow: 30px 30px 50px #000a;
                }
                .video-background {
                  position:absolute;
                  top:calc($dTop * 100%);
                  left:calc($dLeft * 100%);
                  width:calc($rW * 100%);
                  height:calc($rH * 100%);
                  background-color: #000;
                }
                video {
                  position:absolute;
                  top:calc($dTop * 100%);
                  left:calc($dLeft * 100%);
                  width:calc($rW * 100%);
                  height:calc($rH * 100%);
                  object-fit: cover;
                  opacity:0;
                  transition:opacity 0.2s;
                  &.show {
                    opacity:1;
                  }
                }
                img {
                  position:absolute;
                  top:calc($dTop * 100%);
                  left:calc($dLeft * 100%);
                  width:calc($rW * 100%);
                  height:calc($rH * 100%);
                  object-fit: cover;
                  opacity:1;
                }
              }
              .cadre {
                z-index:2;
                position:absolute;
                width:100%;
                height:100%;
                left: 0;
                top: 0;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
          .texte-gauche {
            position:absolute;
            top:2%;
            left:0;
            width:25%;
            @include xl {
              width:22.5%;
            }
            @include lg {
              width:0;
              display:none;
            }
            @include sm {
              width:0;
              display:none;
            }
            height:100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .texte-droite {
            position:absolute;
            right:0;
            top:2%;
            width:25%;
            @include xl {
              width:22.5%;
            }
            @include lg {
              width:35%;
            }
            @include sm {
              width:0;
              display:none;
            }
            height:100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          .texte-inner {
            padding:0 50px;
            text-transform: none;
            text-align:left;
            font-size:16px;
            @include xl {
              font-size:14px;
            }
            @include lg {
              font-size:13px;
            }
            @include md {
              font-size:13px;
            }
            line-height: 1.2;
            letter-spacing: 0.05em;
            max-width:250px;
          }
        }
      }
      &.active {
        pointer-events: all;
        opacity:1;
        .i {
          opacity: 1;
          pointer-events: all;
          cursor:pointer;
        }
        .detail{
          width:100%;
          padding-bottom:calc(50% * $rCadre * 1.05);
          @include lg {
            padding-bottom:calc(60% * $rCadre * 1.05);
          }
          @include sm {
            padding-bottom:calc(90% * $rCadre * 1.05);
          }
          opacity:1;
          transition:opacity 0.5s linear 0.2s, padding-bottom 0.5s;
        }
      }
      &.landing {
        .titre {
          opacity:1;
          color: #FFF;
        }
      }
      .titre {
        position:relative;
        display: inline-block;
        opacity:0.5;
        line-height: 46px;
        padding:0 20px;
        background-color: #FFF0;
        transition: background-color 0.5s, opacity 0.5s,color 0.5s;
        cursor:pointer;
      }
      .titre:hover {
        opacity:1;
        background-color: #FFF;
      }
      &.landing {
        .titre:hover {
          color: #000;
        }
      }
      @include md {
        .titre {
          font-size:18px;
          line-height: 30px;
        }
        .titre:hover {
          opacity:1;
          background-color: #FFF0;
        }
        &.landing {
          .titre:hover {
            color: #000;
          }
        }
      }
    }
  }
}
