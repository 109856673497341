@import 'utils/variables.scss';
.loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity:1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader.closed {
    left: -100%;
    opacity:0;
    transition: opacity 0.5s, left 0.1s linear 0.5s;
}
.loader .main-title {
    font-family:'InterFace-Bold';
    font-size: 24px;
    letter-spacing: 0.1em;
    width:100%;
}
.loader .main-title span.title {
    text-transform: uppercase;
}
.loader .lang-selector {
    font-family:'InterFace-Bold';
    font-size: 12px;
    letter-spacing: 0.1em;
    float:right;
    line-height:30px;
}
.loader .main-nav {
    width:100%;
}
.loader .nav-first {
    font-family:'InterFace-Bold';
    font-size: 12px;
    letter-spacing: 0.1em;
    float:left;
    line-height:30px;
    cursor:pointer;
    text-transform: uppercase;
}
.loader .nav-last {
    font-family:'InterFace-Bold';
    font-size: 12px;
    letter-spacing: 0.1em;
    float:right;
    line-height:30px;
    cursor:pointer;
    text-transform: uppercase;
}
.loader .content {
    position:relative;
    width:100%;
    margin-top:25px;
    margin-bottom:30px;
}
.loader-wrapper {
  position:relative;
  width:554px;
  height:113px;
  flex-shrink: 0;
  background-color: #FFF;
  clip-path:url(#clipping);
  @include sm {
    transform: scale(0.8);
  }
  @include xs {
    transform: scale(0.4);
  }
}
.loader-inner {
  position: absolute;
  width:0;
  left:0;
  top:0;
  height:100%;
  background-color: $noir;
  transition: width 0.5s;
}
.loader .rgpd {
    font-size:14px;
    padding-right:15px;
}
.loader input[type=text] {
    background:none;
    border:none;
    width:100%;
    border-bottom:1px solid black;
    font-size:16px;
    line-height:30px;
}
.loader table {
    width:100%;
    border-collapse: collapse;
}
.loader textarea {
    width:100%;
    font-size:16px;
    border:none;
    border-bottom:1px solid black;
    padding:5px 0;
}
.loader .checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.loader .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.loader .checkbox-container .checkmark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 13px;
  width: 13px;
  background-color: #fff;
  border: 2px solid black;
}
/* On mouse-over, add a grey background color */
.loader .checkbox-container:hover input ~ .checkmark {
  background-color: #eee;
}

/* When the checkbox is checked, add a blue background */
.loader .checkbox-container  input:checked ~ .checkmark {
  background-color: #eee;
}

/* Create the checkmark/indicator (hidden when not checked) */
.loader .checkbox-container .checkmark:after {
  content:"";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.loader .checkbox-container  input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.loader .checkbox-container  .checkmark:after {
  left: 3px;
  top: 0px;
  width: 3px;
  height: 7px;
  border: solid #333;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.loader input[type=submit] {
    background:none;
    border:2px solid black;
    border-radius:6px;
    padding:5px 30px;
    cursor:pointer;
}
.loader input[type=submit]:disabled {
    cursor:inherit;
}
.loader input[type=submit]:hover {
    background-color:#eee;
}
.loader input[type=submit]:disabled:hover {
    background-color:#FFF;
}
.loader-newsletter {
    padding:0 calc(50% - 277px);
    min-height: 3em;
    margin-top:200px;
    margin-bottom:80px;
}
.loader-enter {
    position:absolute;
    text-align:center;
    width:100%;
    left:0;
    bottom:30px;
}
.loader-enter button {
    background:none;
    border:2px solid black;
    border-radius:6px;
    padding:5px 30px;
    cursor:pointer;
}
.loader-enter button:hover {
    background-color:#eee;
}
.loader svg {
    position:absolute;
    top:0;
    left:-100%;
    opacity:0;
}
