@import 'utils/variables.scss';
.message {
    display:flex;
    justify-content: center;
    padding:15px;
    overflow:hidden;
    height:100%;
    .btns {
      margin-top:30px;
      width:100%;
      display:flex;
      justify-content: center;
      align-items: center;
      .rgpd {
        flex-grow: 1
      }
    }
}
.message .main-container {
    position:relative;
}
.message .header h1 {
    font-family:'InterFace-Bold';
    font-size: 24px;
    letter-spacing: 0.1em;
    width:100%;
    margin:0;
    text-align: right;
    text-transform: uppercase;
}
.message .header .retour {
    position:absolute;
    top:0;
    left:0;
    width:33px;
    height:33px;
    object-fit: contain;
    object-position: center;
    cursor: pointer;
}
.message .newsletter {
    width:100%;
    margin-top:55px;
    @include md {
      margin-top:15px;
    }
    padding-top:25px;
    padding-bottom:25px;
    border-bottom:3px solid black;
}
.message .contact {
    width:100%;
    padding-top:25px;
    padding-bottom:25px;
}
.message h3 {
    font-size: 20px;
    @include md {
      margin-top:16px;
    }
    letter-spacing: 0.1em;
    width:100%;
    margin:0 0 25px 0;
    text-transform: uppercase;
}
.message .newsletter strong {
    font-family:'InterFace-Bold';
}
.message .rgpd {
    font-size:14px;
    padding-right:15px;
}
.message input[type=text] {
    background:none;
    border:none;
    width:100%;
    border-bottom:1px solid black;
    margin-bottom:15px;
    font-size:16px;
    line-height:30px;
}
.message table {
    width:100%;
    border-collapse: collapse;
}
.message textarea {
    width:100%;
    font-size:16px;
    border:none;
    border-bottom:1px solid black;
    padding:5px 0;
    background-color: #0000;
}
.message .checkbox-container {
    display: block;
    position: relative;
    width:33px;
    height:33px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex-shrink: 0;
}
.message .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.message .checkbox-container .checkmark {
  position: absolute;
  top: 8px;
  left: 0;
  height: 13px;
  width: 13px;
  background-color: var(--background-color,#FFF);
  border: 2px solid black;
}
/* On mouse-over, add a grey background color */
.message .checkbox-container:hover input ~ .checkmark {
  background-color: #eee;
}

/* When the checkbox is checked, add a blue background */
.message .checkbox-container  input:checked ~ .checkmark {
  background-color: #eee;
}

/* Create the checkmark/indicator (hidden when not checked) */
.message .checkbox-container .checkmark:after {
  content:"";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.message .checkbox-container  input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.message .checkbox-container  .checkmark:after {
  left: 3px;
  top: 0px;
  width: 3px;
  height: 7px;
  border: solid #333;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.message input[type=submit] {
    background:none;
    border:2px solid black;
    border-radius:6px;
    padding:5px 30px;
    cursor:pointer;
}
.message input[type=submit]:disabled {
    cursor:inherit;
}
.message input[type=submit]:hover {
    background-color:#eee;
}
.message input[type=submit]:disabled:hover {
    background-color:var(--background-color,#FFF);
}
