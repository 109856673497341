@import 'utils/variables.scss';
.reflexions {
  padding-top:50px;
  padding-bottom:50px;
  display:flex;
  justify-content: center;
  .reflexions-left, .reflexions-top {
    width:250px;
    margin-top:30px;
    margin-right:150px;
    .reflexions-rub-logo {
      opacity:0.5;
      margin-top:50px;
      background-image: url(../../assets/logo_noir.svg);
      height:180px;
      @include md {
        margin-top:0;
        height:53px;
      }
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .reflexions-rub-texte {
      font-size:20px;
      @include md {
        font-size:16px;
      }
      letter-spacing: 0.1em;
      text-align:right;
    }
    .reflexions-rub-titre {
      margin-top:80px;
      @include md {
        margin-top:30px;
      }
      font-family:'InterFace-Bold';
      font-size:24px;
      letter-spacing: 0.15em;
      text-transform:uppercase;
      text-align:right;
    }
  }
  .reflexions-left {
    display:block;
    width:250px;
    @include xl {
      margin-left:50px;
      margin-right:50px;
    }
    @include md {
      display:none;
    }
  }
  .reflexions-top {
    width:100%;
    display:none;
    margin-bottom:50px;
    @include md {
      display:block;
      margin:0;
    }
    .reflexions-rub-texte, .reflexions-rub-titre  {
      text-align: left;
    }
  }
  .reflexions-inner {
    margin-top:80px;
    @include md {
      margin-top:30px;
    }
    width:900px;
    max-width:100%;
    padding:0 30px;
    box-sizing: border-box;
    .reflexions-logo {
      opacity:0.5;
      margin:20px 0;
      background-image: url(../../assets/logo_noir.svg);
      height:53px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .reflexions-logo-noir {
      opacity:0.7;
      margin-top:50px;
      background-image: url(../../assets/logo_noir.svg);
      height:30px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .reflexions-date {
      font-size:16px;
      letter-spacing: 0.15em;
      text-align:left;
      margin-top:30px;
      span {
        margin-left:0.5em;
        font-family:'InterFace-Bold';
      }
    }
    .reflexions-titre {
      margin:10px 0;
      font-family:'InterFace-Bold';
      font-size:24px;
      letter-spacing: 0.15em;
      text-transform:uppercase;
      text-align:left;
    }
    .reflexions-texte {
      p:first-child {
        margin-top:0;
      }
      font-size:20px;
      @include md {
        font-size:16px;
      }
      letter-spacing: 0.1em;
      text-align:left;
    }
  }
}
