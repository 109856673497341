@use "sass:math";
$main-width: 1920px;
$main-height: 1038px;
$h2Size: 80px;
$beige: #f2d8a7;
$beige-alpha: #f2d8a780;
$bleu: #2b37d4;
$bleu-alpha: #2b37d488;
$hauteurCredits: 4500px;
$loader-bg:#ffffff;
$accueil-bg:#c8d2d5;
$presentation-bg:#ffffff;
$message-bg:#ffffff;
$gris:#c8d2d5;
$noir:#333;

$rVideo:0.5625;
$rpVideo:100%*$rVideo;
$wVideo:1;
$hVideo:$wVideo*$rVideo;
$lCadre:$wVideo*0.1385;
$wCadre:$wVideo+2*$lCadre;
$hCadre:$hVideo+2*$lCadre;
$rCadre:math.div($hCadre, $wCadre);
$rpCadre:100%*$rCadre;

$rW:math.div($wVideo, $wCadre);
$rH:math.div($hVideo, $hCadre);
$dLeft:math.div(1 - $rW, 2);
$dTop:math.div(1 - $rH, 2);

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

// Large tablets and desktops
$screen-xxl-min: 1600px;

// XSmall devices
@mixin xs {
   @media (max-width: #{$screen-sm-min}) {
       @content;
   }
}

// Small devices
@mixin sm {
    @media (max-width: #{$screen-md-min}) {
        @content;
    }
}

// Medium devices
@mixin md {
    @media (max-width: #{$screen-lg-min}) {
        @content;
    }
}

// Large devices
@mixin lg {
    @media (max-width: #{$screen-xl-min}) {
       @content;
    }
}

// Extra large devices
@mixin xl {
    @media (max-width: #{$screen-xxl-min}) {
       @content;
    }
}
@mixin viewPort {
  min-height:600px;
  min-height:calc(40vw + 90px);
  @include lg {
    min-height:calc(48vw + 90px);
  }
  @include sm {
    min-height:calc(72vw + 90px);
  }
  @include xs {
    min-height:460px;
  }
}
